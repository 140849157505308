<template src="./Map.html"/>
<script>
import Config from '../config/Map.json'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import geoCountyData from '../assets/map/megye.json'
import geoDistrictData from '../assets/map/valasztokerulet.json'
import egyeniValasztokeruletek from '../assets/api/egyeni-valasztokeruletek.json'
import sizeReporter from "../mixins/sizeReporter";

export default {
  mixins: [sizeReporter],
  components: {
    vSelect,
  },
  props: {
    mapType: {
      type: String,
      default: 'leaflet',
    },
  },
  data() {
    return {
      district: '',
      county: '01',
      geoJsonCountyData: null,
      geoJsonDistrictData: null,
      voteMap: {
        map: null,
        countyLayer: {},
        districtLayer: {},
      },
      selectedRegion: {
        county: null,
        countyLayer: null,
        district: null,
        area: null,
      },
      voteData: {
        data: [],
        selected: null,
      },
      search: {},
      isSearch: false,
    }
  },
  async beforeMount() {
    let uri = window.location.href.split('?')
    if(uri.length === 2) {
      let vars = uri[1].split('&')
      let getVars = {}
      let tmp = ''
      vars.forEach(function (v) {
        tmp = v.split('=');
        if (tmp.length === 2)
          getVars[tmp[0]] = tmp[1];
      });
      console.log(getVars)
      if (getVars.district && getVars.district !== '' && getVars.county === '') {
        getVars.county = getVars.district.substring(0, 2)
      }
      this.district = getVars.district
      this.county = getVars.county
      this.search.district = getVars.district
      this.search.county = getVars.county
    }
  },
  async mounted() {
    this.initData().then(() => {
      this.$nextTick(() => {
        this.initMap()
        this.showMap()
      })
    })
  },
  computed: {
  },
  methods: {
    async initData() {
      const result = egyeniValasztokeruletek
      this.voteData.data = result.content || []
    },
    initMap() {
      this.voteMap.map = this.$L.map('map', Config.mapOptions)
      this.initCountyLayer()
    },
    initCountyLayer() {
      this.voteMap.countyLayer = this.$L.geoJSON(geoCountyData, {
        style: Config.mapStyles.default,
        onEachFeature: (feature, layer) => {
          layer.on({
            mouseover: (e) => {
              const layer = e.target
              layer.setStyle({ ...Config.mapStyles.selected, ...Config.mapStyles.hover })
            },
            mouseout: (e) => {
              const layer = e.target
              layer.setStyle((this.selectedRegion.county === layer.feature.properties.code) ? Config.mapStyles.selected : Config.mapStyles.default)
            },
            click: (e) => {
              const layer = e.target
              this.voteMap.countyLayer.setStyle(Config.mapStyles.default)
              layer.setStyle(Config.mapStyles.selected)
              this.selectedRegion.county = layer.feature.properties.code
              this.selectedRegion.countyLayer = layer
            },
          })
        },
      })
    },
    initDistrictLayer(countyId) {
      this.voteMap.districtLayer = this.$L.geoJSON(geoDistrictData.features.filter(feature => feature.properties.county_id === countyId), {
        style: Config.mapStyles.default,
        onEachFeature: (feature, layer) => {
          layer.on({
            mouseover: (e) => {
              const layer = e.target
              layer.setStyle({ ...Config.mapStyles.selected, ...Config.mapStyles.hover })
            },
            mouseout: (e) => {
              const layer = e.target
              layer.setStyle((this.selectedRegion.area === layer.feature.properties.oevk_id) ? Config.mapStyles.selected : Config.mapStyles.default)
            },
            click: (e) => {
              const layer = e.target
              this.voteMap.districtLayer.setStyle(Config.mapStyles.default)
              layer.setStyle(Config.mapStyles.selected)
              this.selectedRegion.area = layer.feature.properties.oevk_id
              this.voteData.selected = this.voteData.data.filter(e => e.oevkId === layer.feature.properties.oevk_id.toString())[0]
            },
          })
        },
      })
    },
    changeMap() {
      if (this.selectedRegion.district === null) {
        this.selectedRegion.district = this.selectedRegion.county
        this.voteMap.map.removeLayer(this.voteMap.countyLayer)
        this.initDistrictLayer(this.selectedRegion.district)
        this.voteMap.districtLayer.addTo(this.voteMap.map)
        const b = this.selectedRegion.countyLayer.getBounds()
        this.voteMap.map.flyToBounds(b, Config.mapStyles.animate)
        const districtLayer = this.voteMap.districtLayer.getLayers()[0]
        districtLayer.setStyle(Config.mapStyles.selected)
        this.selectedRegion.area = districtLayer.feature.properties.oevk_id
        this.voteData.selected = this.voteData.data.filter(e => e.oevkId === districtLayer.feature.properties.oevk_id.toString())[0]
      } else {
        this.voteMap.districtLayer.setStyle(Config.mapStyles.default)
        this.voteMap.map.removeLayer(this.voteMap.districtLayer)
        this.voteMap.countyLayer.addTo(this.voteMap.map)
        this.voteMap.map.flyTo(Config.mapOptions.center, Config.mapOptions.zoom, Config.mapStyles.animate)
        this.selectedRegion.area = this.selectedRegion.district = this.voteData.selected = null
      }
    },
    showMap() {
      if (this.search.county.length > 0) {
        this.voteMap.countyLayer.addTo(this.voteMap.map)
        const layer = this.voteMap.countyLayer.getLayers().filter(layer => layer.feature.properties.code.toString() === this.search.county)
        if (layer[0]) {
          this.selectedRegion.county = layer[0].feature.properties.code
          this.selectedRegion.countyLayer = layer[0]
          layer[0].setStyle(Config.mapStyles.selected)
        }
      }
      if (this.search.district.length > 0) {
        const county = this.search.district.substring(0, 2)
        const layer = this.voteMap.countyLayer.getLayers().filter(layer => layer.feature.properties.code.toString() === county)
        if (layer[0]) {
          this.selectedRegion.county = layer[0].feature.properties.code
          this.selectedRegion.countyLayer = layer[0]
          this.selectedRegion.district = county
          layer[0].setStyle(Config.mapStyles.selected)
          this.initDistrictLayer(this.selectedRegion.district)
          this.voteMap.districtLayer.addTo(this.voteMap.map)
          this.voteMap.map.flyToBounds(layer[0].getBounds(), Config.mapStyles.animate)
          const districtLayer = this.voteMap.districtLayer.getLayers().filter(layer => layer.feature.properties.oevk_id.toString() === this.search.district)
          if (districtLayer[0]) {
            districtLayer[0].setStyle(Config.mapStyles.selected)
            this.selectedRegion.area = districtLayer[0].feature.properties.oevk_id
            this.voteData.selected = this.voteData.data.filter(e => e.oevkId === this.search.district)[0]
          }
        }
      }
    },
    searchMap(search) {
      this.voteMap.countyLayer.setStyle(Config.mapStyles.default)
      if (this.selectedRegion.district === null) {
        this.voteMap.map.removeLayer(this.voteMap.countyLayer)
      } else {
        this.voteMap.districtLayer.setStyle(Config.mapStyles.default)
        this.voteMap.map.removeLayer(this.voteMap.districtLayer)
      }
      this.search.county = ''
      this.search.district = search.oevkId
      this.isSearch = false
      this.showMap()
    },
    orderCandidates(candidates) {
      const sum = Object.entries(candidates).reduce((prev, [key, candidate]) => prev + parseInt(candidate.votes), 0) || 0
      const result = Object.entries(candidates).map(([key, candidate]) => {
        const votes = parseInt(candidate.votes)
        candidate.percent = Math.round((((votes / sum) || 0) * 100) * 10) / 10
        return candidate
      })
      result.sort((e1, e2) => e2.percent - e1.percent)
      return result
    },
  },
}
</script>
<style lang="scss">
@import '../assets/scss/vars.scss';
@import '../assets/scss/map.scss';
</style>
